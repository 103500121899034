@use '@angular/material' as mat;
@use "./custom-theme" as theme;
@use "./variables" as vars;
@use "./mixins" as mixins;
@use "./custom-flexboxgrid";

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($ui-typography);`
@include mat.all-component-typographies(theme.$ui-typography);

html, body {
  margin: 0;
  // Turned off, because it breaks anchor scrolling (https://github.com/angular/angular/issues/24547)
   height: 100%; // Temp turn back on since angular-material requires this for some reason
}

* {
  font-family: vars.$ui-font;
  line-height: vars.$line-height;
  color: vars.$dark-blue;
  font-size: 12px;
  @media screen and (min-width: vars.$flexbox-small-breakpoint) {
    font-size: 15px;
  }
}

h1 {
  font-weight: vars.$font-weight-bold;
  font-size: 3.2rem;
}

h2 {
  font-weight: vars.$font-weight-bold;
  font-size: 2.533rem;
}

h3 {
  font-weight: vars.$font-weight-bold;
  font-size: 2rem;
}

strong {
  font-weight: vars.$font-weight-bold;
}

a {
  font-weight: vars.$font-weight-bold;
  cursor: pointer;
}

p {
  font-weight: vars.$font-weight-regular;
}

button {
  cursor: pointer;
}

.wv-title {
  font-family: vars.$ui-font;
  font-weight: vars.$font-weight-bold;
  font-size: 1.2rem;
}

.wv-padding {
  padding: 0 2rem;
}

.wv-center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.wv-bold-button {
  font-weight: vars.$font-weight-bold;
  background: none;
  border: none;
}

.wv-error {
  color: red;
}

.wv-responsive-margin {
  @include mixins.responsive-margin();
}

.wv-responsive-padding {
  @include mixins.responsive-padding-left();
  @include mixins.responsive-padding-right();
}

.wv-responsive-padding-left {
  @include mixins.responsive-padding-left();
}

.wv-padding-top {
  padding-top: 6rem;
}

.wv-flex {
  display: flex;
}

.wv-absolute-bear {
  position: absolute;
  bottom: -7rem;
  right: 0;
  height: 14.5rem;
  @media screen and (min-width: vars.$flexbox-small-breakpoint) {
    bottom: -5rem;
    right: 9rem;
  }
  @media screen and (min-width: vars.$desktop-xl) {
    bottom: -2rem;
  }
}

.wv-image {
  object-fit: contain;
  background: vars.$light-grey;
  width: 100%;
  height: 100%;
}

.transparent-snackbar {
  background: transparent !important;
  box-shadow: none !important;
  color: red !important;
}

@mixin tag-color($color, $code) {
  .actie-tag-#{$color} {
    color: white;
    background: #{$code};
  }

  .tag-#{$color} {
    background: white;
    color: vars.$dark-blue !important;
    box-shadow: 0 0 0 1.5pt #{$code};
    fa-icon {
      path {
        color: vars.$dark-blue !important;
      }
    }
    &.color-picker {
      background: #{$code};
    }
    &.select {
      color: white !important;
      background: #{$code};
      fa-icon {
        path {
          color: white !important;
        }
      }
    }
  }
}

@include tag-color('red', #F24726);
@include tag-color('light-green', #CEE741);
@include tag-color('light-blue', #12CDD4);
@include tag-color('yellow', #FAC710);
@include tag-color('dark-red', #DA0063);
@include tag-color('green', #8FD14F);
@include tag-color('blue', #2D9BF0);
@include tag-color('grey', #808080);
@include tag-color('purple', #9510AC);
@include tag-color('dark-green', #0CA789);
@include tag-color('dark-blue', #4141BB);
@include tag-color('black', #1A1A1A);
@include tag-color('wv-orange', #F7A884);
@include tag-color('wv-blue', vars.$dark-blue);
@include tag-color('wv-tool-tag-blue',  #517acf);

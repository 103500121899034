@use "./variables" as vars;

// Shorthand cannot be used since it would overwrite top and bottom properties

@mixin responsive-padding-left {
  padding-left: 2rem;
  @media only screen and (min-width: vars.$flexbox-small-breakpoint) {
    padding-left: calc(50vw - calc(#{vars.$flexbox-content-small-width} / 2));
  }
  @media only screen and (min-width: vars.$flexbox-large-breakpoint) {
    padding-left: calc(50vw - calc(#{vars.$flexbox-content-large-width} / 2));
  }
  @media only screen and (min-width: vars.$flexbox-xlarge-breakpoint) {
    padding-left: calc(50vw - calc(#{vars.$flexbox-content-xlarge-width} / 2));
  }
}

@mixin responsive-padding-right {
  padding-right: 2rem;
  @media only screen and (min-width: vars.$flexbox-small-breakpoint) {
    padding-right: calc(50vw - calc(#{vars.$flexbox-content-small-width} / 2));
  }
  @media only screen and (min-width: vars.$flexbox-large-breakpoint) {
    padding-right: calc(50vw - calc(#{vars.$flexbox-content-large-width} / 2));
  }
  @media only screen and (min-width: vars.$flexbox-xlarge-breakpoint) {
    padding-right: calc(50vw - calc(#{vars.$flexbox-content-xlarge-width} / 2));
  }
}

@mixin responsive-margin {
  margin-left: 2rem;
  margin-right: 2rem;
  @media only screen and (min-width: vars.$flexbox-small-breakpoint) {
    margin-left: calc(50vw - calc(#{vars.$flexbox-content-small-width} / 2));
    margin-right: calc(50vw - calc(#{vars.$flexbox-content-small-width} / 2));
  }
  @media only screen and (min-width: vars.$flexbox-large-breakpoint) {
    margin-left: calc(50vw - calc(#{vars.$flexbox-content-large-width} / 2));
    margin-right: calc(50vw - calc(#{vars.$flexbox-content-large-width} / 2));
  }
  @media only screen and (min-width: vars.$flexbox-xlarge-breakpoint) {
    margin-left: calc(50vw - calc(#{vars.$flexbox-content-xlarge-width} / 2));
    margin-right: calc(50vw - calc(#{vars.$flexbox-content-xlarge-width} / 2));
  }
}
